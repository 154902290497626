import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import "../styles/pages/article.scss"
import SEO from "../components/seo"
import Video from "../components/partials/video";
import BackgroundImage from "gatsby-background-image";
import ImageGallery from 'react-image-gallery';
import DayStep from "../components/partials/dayStep";

function createGallery(gallery) {
  let galleryArr = [];
  if (gallery) {
    for (const [index, value] of gallery.entries()) {
      galleryArr.push({index: index, original: value.source_url, thumbnail: value.source_url})
    }
  } else {
    galleryArr = null;
  }

  return(galleryArr);
}

function createVideo(videoUrl, videoTitle) {
  let html = '';
  if (videoUrl !== null) {
    html =<Video
      videoSrcURL={videoUrl}
      videoTitle={videoTitle}
    />;
  }

  return html;
}

function convertUnicode(input) {
  if (input.includes('&#8211;')) {
    input = input.replace('&#8211;', '-');
  }
  return input;
}

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost;
    const postTitle = convertUnicode(post.title);
    const images = createGallery(post.acf.gallery);

    return (
      <Layout>
        <SEO title={postTitle}/>
        <div className="article">
          {(post.featured_media !== null && post.featured_media.localFile !== null) ?
            <BackgroundImage Tag="div"
                             className="card-article__cover-img"
                             fluid={post.featured_media.localFile.childImageSharp.fluid}>
            </BackgroundImage>
            :
            ''
          }

          {/* share for fb*/}
          {/*https://mariohernandez.io/blog/adding-social-share-links-to-gatsby/*/}

          <div className="container">
            <h1 className="article__title" dangerouslySetInnerHTML={{ __html: post.title }}>
            </h1>
            <div className="article__content text-style" dangerouslySetInnerHTML={{__html: post.content}}>
            </div>

            {/* check if article is trip type */}
            {post.acf.day !== null ?
              <DayStep days={post.acf.day}/>
            :
            ''
            }

            {post.acf.videoLink !== '' ?
              createVideo(post.acf.videoLink, post.title)
              :
              ''
            }

            {images ? <ImageGallery items={images}
                                    showPlayButton={false}
                                    lazyLoad={true}
                                    showFullscreenButton={false}
                                    useBrowserFullscreen={false}/> : ''}
          </div>


        </div>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 95, maxWidth: 6000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        gallery {
          id
          source_url
        }
        videoLink
        day {
          description
          gallery {
            id
            source_url
          }
        }
      }
    }
  }
`
