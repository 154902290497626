import React from 'react'
import ImageGallery from 'react-image-gallery';

function setupImages(gallery) {
  let galleryArr = [];
  if (gallery) {
    for (const [index, value] of gallery.entries()) {
      galleryArr.push({index: index, original: value.source_url, thumbnail: value.source_url})
    }
  } else {
    galleryArr = null;
  }

  return galleryArr;
}

function createGallery(gallery) {
  const images = setupImages(gallery);
  return <ImageGallery items={images}
                       showPlayButton={false}
                       lazyLoad={true}
                       showFullscreenButton={false}
                       useBrowserFullscreen={false}/>;
}

const DayStep = ({ days }) => {
  return (
    <div className="days">
      {Object.keys(days).map((i) => (
        <div className="days__item" key={`day-` + i}>
          <div className="days__description text-style" dangerouslySetInnerHTML={{ __html: days[i].description }}>
          </div>
          {days[i].gallery !== null ?
            createGallery(days[i].gallery)
          :
            ''
          }
        </div>
      ))}
    </div>
  )
}


export default DayStep
